import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I needed a way to upload a massive folder to Dropbox from a Linux command-line, so I made `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/dropbox-folder-uploader"
      }}><inlineCode parentName="a">{`dropbox-folder-uploader`}</inlineCode></a>{`. It is written in Node.js and uses `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/node-fetch"
      }}><inlineCode parentName="a">{`node-fetch`}</inlineCode></a>{` to recursively upload a folder to Dropbox with a given API token.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      